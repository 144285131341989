import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title:'RPG CENTRO - Reeducacion Postural Global :: INICIO',
    },
    component: Home
  },
   {
    path: '/quienes',
    name: 'Quienes',
    meta: {
      title:'RPG CENTRO - Reeducacion Postural Global :: Quienes Somos',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Quienes.vue')
  },
  {
    path: '/sedes',
    name: 'Sedes',
    meta: {
      title:'sedes',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Sedes.vue')
  },
  {
    path: '/rpg',
    name: 'RPG',
    meta: {
      title:'RPG',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RPG.vue')
  },
  {
    path: '/cv',
    name: 'CV',
    meta: {
      title:'CV',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CV.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title:'ABOUT',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// const DEFAULT_TITLE = 'RPG Centro';
// router.afterEach((to, from) => {
//     // Use next tick to handle router history correctly
//     // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
//     Vue.nextTick(() => {
//         document.title = to.meta.title || DEFAULT_TITLE;
//     });
// });

router.beforeEach((to, from, next) =>{
  document.title = `${to.meta.title}`;
  next();
})

export default router
