<template>
<section id="Gallery">
   <div class="content">
      <h6 class="title is-6 has-text-white">Galleria</h6>
    </div>
  <div class="columns">
    <div 
      class="column" 
      v-for="image in images.slice(0, 2)" 
      v-bind:key="image.id">
        <figure class="image is-3by2">
          <img 
            :src="getImgUrl(image.smallSRC)" 
            :alt="image.title"
            :title="image.title"
            v-on:click="showModal"
            v-bind:data-image-original="getImgUrl(image.originalSRC)"
            data-target="modal-bis modal-button"
            />
        </figure>
    </div>
  </div>
  <div class="columns">
    <div 
      class="column" 
      v-for="image in images.slice(2, 4)" 
      v-bind:key="image.id">
        <figure class="image is-3by2">
          <img 
            :src="getImgUrl(image.smallSRC)" 
            :alt="image.title"
            :title="image.title"
            v-on:click="showModal"
            v-bind:data-image-original="getImgUrl(image.originalSRC)"
            data-target="modal-bis modal-button"
            />
        </figure>
    </div>
  </div>
  <div class="columns">
    <div 
      class="column" 
      v-for="image in images.slice(4, 6)" 
      v-bind:key="image.id">
        <figure class="image is-3by2">
          <img 
            :src="getImgUrl(image.smallSRC)" 
            :alt="image.title"
            :title="image.title"
            v-on:click="showModal"
            v-bind:data-image-original="getImgUrl(image.originalSRC)"
            data-target="modal-bis modal-button"
            />
        </figure>
    </div>
  </div>
</section>
   <div class="modal" id="modal-bis" v-bind:class="{ 'is-active': modalIsShowed }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <p class="image is-4by3">
          <img :src="imagesrc" alt="">
        </p>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="modalIsShowed=false"></button>
    </div>
</template>

<script>
export default {
  name: 'Gallery',
  props: {
    msg: String
  },
  data: function () {
    return {
      modalIsShowed: false,
      imagesrc: '',
      images: [
        { 
          id: 1, 
          title: 'RPG', 
          smallSRC: 'smallrpg.jpg', 
          originalSRC: 'originalrpg.jpg' 
          //smallSRC: 'https://farm4.staticflickr.com/3765/13295233434_b81836a1a5_s.jpg', 
          //originalSRC: 'https://farm4.staticflickr.com/3765/13295233434_b81836a1a5.jpg' 
        },
        { 
          id: 2, 
          title: 'RPG Centro Cordoba', 
          //smallSRC: 'https://farm4.staticflickr.com/3765/13295233434_b81836a1a5_s.jpg', 
          smallSRC: 'smallkinesio.jpg', 
          originalSRC: 'originalkinesio.jpg' 
        },
        { 
          id: 3, 
          title: 'RPG Centro Cordoba', 
          //smallSRC: 'https://farm4.staticflickr.com/3765/13295233434_b81836a1a5_s.jpg', 
          smallSRC: 'smallosteo.jpg', 
          originalSRC: 'originalosteo.jpg' 
        },
        { 
          id: 4, 
          title: 'RPG Centro Cordoba', 
          //smallSRC: 'https://farm4.staticflickr.com/3765/13295233434_b81836a1a5_s.jpg', 
          smallSRC: 'smallplantillas.jpg', 
          originalSRC: 'originalplantillas.jpg' 
        },
        { 
          id: 5, 
          title: 'RPG Centro Cordoba', 
          //smallSRC: 'https://farm4.staticflickr.com/3765/13295233434_b81836a1a5_s.jpg', 
          smallSRC: 'smallmaso.jpg', 
          originalSRC: 'originalmaso.jpg' 
        },
        { 
          id: 6, 
          title: 'RPG Centro Cordoba', 
          //smallSRC: 'https://farm4.staticflickr.com/3765/13295233434_b81836a1a5_s.jpg', 
          smallSRC: 'smallktape.jpg', 
          originalSRC: 'originalktape.jpg' 
        },
        // { id: 2, title: 'RPG Centro Cordoba', smallSRC: 'https://farm4.staticflickr.com/3949/14931784084_6ec6803b01_s.jpg', originalSRC: 'https://farm4.staticflickr.com/3949/14931784084_6ec6803b01.jpg' },
        // { id: 3, title: 'RPG Centro Cordoba', smallSRC: 'https://farm4.staticflickr.com/3945/15365928909_405567b4ef_s.jpg', originalSRC: 'https://farm4.staticflickr.com/3945/15365928909_405567b4ef.jpg' },
        // { id: 4, title: 'RPG Centro Cordoba', smallSRC: 'https://farm4.staticflickr.com/3952/15366933490_67513a00bb_s.jpg', originalSRC: 'https://farm4.staticflickr.com/3952/15366933490_67513a00bb.jpg' },
        // { id: 5, title: 'RPG Centro Cordoba', smallSRC: 'https://farm6.staticflickr.com/5603/15553411402_4a3c9462d6_s.jpg', originalSRC: 'https://farm6.staticflickr.com/5603/15553411402_4a3c9462d6.jpg' },
        // { id: 6, title: 'RPG Centro Cordoba', smallSRC: 'https://farm8.staticflickr.com/7150/6736530155_59fd7a99f0_s.jpg', originalSRC: 'https://farm8.staticflickr.com/7150/6736530155_59fd7a99f0.jpg' }
      ]
    }
  },
  methods: {
    showModal: function (event) {
      //alert ('entre')
      this.imagesrc = event.target.dataset.imageOriginal
      this.modalIsShowed = true
    },
    getImgUrl: function(image) {
      return require('../assets/gallery/' + image)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
    color: #fff;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 16px;
}
</style>
