<template>
  <div id="RPGSite">
    <MyHeader/>
    <router-view/>
    <MyFooter/>
  </div>
  
</template>
<script>
import MyHeader from './components/Header.vue'
import MyFooter from './components/Footer.vue'
export default {
  name: 'RPGSite',
  components: {
    MyHeader,
    MyFooter 
  }
}
</script>

<style>
@import "~bulma/css/bulma.css";
:root {
  --main-text-color: #0076f9;
}
/* #app { 
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
