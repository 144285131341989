<template>
  <div class="slider hero">
      <figure class="image is-5by3">
        <transition name="slide-fade" mode="out-in">
          <img 
            :src="currentImage" 
            :alt="currentTitle"
            :title="currentTitle"
            :key="chosenImage"
            @load="show=true"
            />
        </transition>  
      </figure>
      <!-- <div 
        v-for="image in images" 
        v-bind:key="image.id"> 
          <figure v-if="(image.id == chosenImage)" >
            <img 
              :src="getImgUrl(image.original)" 
              :alt="image.title"
              :title="image.title"
              />
          </figure>
      </div> -->
<!-- 

    <img 
      alt="Acomodar Espalda" 
      width="1569" 
      src="https://farm8.staticflickr.com/7107/13293866975_d7d8dfddc6_o.png" 
      class="defaultimg" /> -->
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: {
    msg: String,
  },
   data: function () {
    return {
      show: false,
      currentImage:  "",
      currentTitle: "",
      chosenImage: 0,
      images: [
        { 
          id: 1, 
          title: 'RPG Centro Cordoba', 
          //smallSRC: 'https://farm4.staticflickr.com/3765/13295233434_b81836a1a5_s.jpg', 
          original: 'home1.jpg' 
        },
        { 
          id: 2, 
          title: 'RPG Centro Cordoba', 
          //smallSRC: 'https://farm4.staticflickr.com/3765/13295233434_b81836a1a5_s.jpg', 
          original: 'home3.jpg' 
        },
        { 
          id: 3, 
          title: 'RPG Centro Cordoba', 
          //smallSRC: 'https://farm4.staticflickr.com/3765/13295233434_b81836a1a5_s.jpg', 
          original: 'home2.jpg' 
        }
      ]
    }
  },
  methods: {
    getImgUrl: function(image) {
      return require('../assets/home/' + image)
    }
  },
  mounted: function () {
    this.currentImage = this.getImgUrl(this.images[this.images.length-1].original)
    this.currentTitle = this.images[this.images.length-1].title
    this.intervalObject = setInterval(()=>{
      // alert(this.images[this.chosenImage].original);
      if (this.chosenImage === this.images.length){
        this.chosenImage = 0    
      }
      this.currentImage = this.getImgUrl(this.images[this.chosenImage].original)
      this.currentTitle = this.images[this.chosenImage].title
      this.chosenImage ++
    },5000); 
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img{
    vertical-align: middle;
     max-width: 100%;
    max-height: 100%;
  }
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}


</style>
