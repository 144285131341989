<template>
  <div class="SliderContainer">
    <Slider :images="images" :intervalDuration="slideDuration"/> 
  </div>
</template>

<script>
// @ is an alias to /src
import Slider from '@/components/Slider.vue'

export default {
  name: 'Home',
  components: {
    Slider
  },
  data(){
    return{
      images : [
        {
          id: 0,
          url : "https://blognovasan.com/wp-content/uploads/2020/02/que-es-el-kinesiotape.jpg",
          title: "RPG 1"
        },
        {
          id: 1,
          url : "https://blognovasan.com/wp-content/uploads/2020/02/que-es-el-kinesiotape.jpg",
          title: "RPG 1"
        },
        {
          id: 2,
          url : "https://blognovasan.com/wp-content/uploads/2020/02/que-es-el-kinesiotape.jpg",
          title: "RPG 3"
        }
      ],
      slideDuration : 4000
    }
  }
}
</script>

<style>
body{
  margin:0;
}
#SliderContainer{
  width: 100%;
  height: 100vh;
}
</style>