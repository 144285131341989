<template>
  <div class="columns is-mobile is-centered">
    <div class="column is-narrow has-text-white">
      <p>
        ©{{ new Date().getFullYear() }} RPG Centro, All Rights Reserved | Design by
        <a href="http://www.southern-sys.com/" target="_blank">Southern Systems</a>
      </p>
    </div>
    <div class="column is-narrow">
      <a target="_new" href="https://www.facebook.com/RPGCentro" class="icon has-text-white">
        <font-awesome-icon :icon="['fab', 'facebook-f']" />
      </a>
      <a target="_new" href="https://twitter.com/rpgcentro" class="icon has-text-white">
        <font-awesome-icon :icon="['fab', 'twitter']" />
      </a>
      <a target="_new" href="https://feeds.feedburner.com/RPGCentro" class="icon has-text-white">
        <font-awesome-icon :icon="['fa', 'rss']" />
      </a>
      <a target="_new" href="https://www.linkedin.com/company/rpg-centro" class="icon has-text-white">
        <font-awesome-icon :icon="['fab', 'linkedin-in']" />
      </a>
      <a target="_new" href="https://www.instagram.com/rpg_centro/" class="icon has-text-white">
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Copyright",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
